/*********
Cores da LOGO
Laranja #ff6600
**********/

.bg-top {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 200px;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-resize {
    resize: none;
}

.t-h1 {
    font-size: 2.358em;
    font-weight: 700;
    text-transform: uppercase;
}

.t-h2 {
    font-size: 1.758em;
    font-weight: 600;
    text-transform: uppercase;
}

.mb-3 {
    margin-bottom: 3rem;
}

.principais-clientes figure {
    display: block;
    padding: 10px;
    border: 2px solid #f4f4f4;

}

.breadcrumb-ativo {
    font-weight: 600;
    color: #4b4b4b;
}

.bkg-border-green {
    background-color: #ff6600 !important;
    border-color: #ff6600 !important;
    transition: linear 0.2s;
}

.bkg-border-green:hover{
    background: #cc5608  !important;
    border-color: #cc5608  !important;
}

.main-logo > img {
    max-width: 180px;
}

#main-nav > ul > li > a:hover,
#main-nav > ul > li.current-menu-item > a {
    color: #ff6600 !important;
}

#site-header.style-1 .header-search-icon:hover,
#site-header.style-5 .header-search-icon:hover {
    color: #ff6600 !important;
}

.wprt-lines.custom-3 .line-1,
.wprt-lines.custom-5 .line-1{
    background-color: #ff6600 !important;
}

.bg_img {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 220px;
    background-position: center;
}

.service-item .service-item-wrap {
    padding-top: 18px;
}

.service-item .service-item-wrap .title {
    margin-bottom: 15px;
}

.service-item .title a:hover {
    color: #ff6600 !important;
}

#footer-widgets .widget-title > span {
    text-transform: uppercase;
}

#footer-widgets.style-1 .widget .widget-title > span::after,
#footer-widgets.style-2 .widget .widget-title > span::after {
    background-color: #ff6600 !important;
}

#copyright > span {
    color: #fff;
    font-size: 14px;
}

/*Carousel empresa*/
#owl-empresa .item figure img {
    display: block;
    width: 100%;
    height: auto;
}

#breadcrumbs {
    background: #f8f8f8;
    padding-top: 15px;
    padding-bottom: 15px;
}
#breadcrumbs .breadcrumb-trail > a {
    font-family: 'Montserrat';
    color: #777;
}
#breadcrumbs .breadcrumb-trail .sep {
    padding-left: 3px;
    padding-right: 3px;
}

#breadcrumbs .breadcrumb-trail .trail-end {
    font-family: 'Montserrat';
    color: #4b4b4b;
    font-weight: 500;
}

#sidebar .widget.widget_recent_news .recent-news .thumb {
    width: 100px;
}

.size-img-noticia {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 390px;
}

.size-img-side-noticia {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 85px;
}

.img-galeria {
    background-repeat: no-repeat;
    background-size: cover;
    width: 69px;
    height: 52px;
}

.wprt-partners .partner {
    border: 2px solid #f6f6f6;
    margin: 0 10px;
    transition: 0.2s;
}

.wprt-partners .partner:hover {
    background: #f6f6f6;
}

.wprt-partners .partner a img {
    max-width: 170px;
}

.wprt-clientes .clientes {
    border: 2px solid #f6f6f6;
    margin: 0 10px;
    transition: 0.2s;
}

.wprt-clientes .owl-controls .owl-buttons div {
    color: #fff;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin: 5px;
    width: 30px;
    height: 30px;
    /*padding: 15px 15px;*/
    font-size: 12px;
    border: 2px solid;
    border-color: #ff6600;
    border-radius: 0;
    background: transparent;
    opacity: inherit;
}

/* Responsivo */
@media only screen and (max-width: 991px) {

    .galeria-imagens .galeria {
        display: inline-block;
        padding-bottom: 15px;
    }

}

@media only screen and (max-width: 767px) {

    .bg-top {
        height: 100px;
    }

    .wrap-submit .wprt-button.big {
        padding: .778em 2.5em;
        font-size: 1em;
    }

    .blog article.hentry {
        margin-bottom: 25px;
    }

    .galeria-imagens {
        text-align: center;
    }

    #main-nav-mobi ul.menu-mobile > li > a {
        line-height: 40px;
    }
}